import React, {Component} from "react";

export class Loading extends Component {
  render() {
    return <div>...</div>
  }

}

export default Loading
