import './App.css';
import React, {Suspense} from "react";
import routes from "./Routes";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {Loading} from "./pages/Loading";


function App() {

  const menu = routes.map((route, index) => {
    return (route.component) ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={props => (
          <route.component {...props} />
        )}
      />
    ) : null
  })

  return (
    <div className="App">
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>{menu}</Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
