import React from "react";

const landingComp = React.lazy(() => import('./pages/Landing'));

// import Landing from "./pages/Landing";
// const landingComp = Landing

const public_routes = [
  {path: '/', exact: true, name: 'landing', component: landingComp}
]

const routes = [
  ...public_routes
]

export default routes